@import url("https://fonts.googleapis.com/css2?family=Comfortaa&display=swap");

html,
body,
#root {
  overflow: hidden;
  word-spacing: 0;
  letter-spacing: -1px;
  font-family: "Comfortaa", cursive;
  height: 100%;
  margin: 0;
  background: #000000;
}

canvas {
  background-image: linear-gradient(90deg, #000000, #141415);
}

#load-model {
  color: white;
  background-color: black;
  text-align: center;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

#loader-test {
  position: absolute;
  background-color: black;
  color: white;
  z-index: 999;
  top: 50vh;
  left: 50vw;
  transform: translateX(-50%) translateY(-50%);
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
}

#fullscreen-btn {
  user-select: none;
  position: absolute;
  padding: 1rem;
  border-bottom-right-radius: 0.8rem;
  border-top: none;
  font-weight: bold;
  background-color: #ffffff6c;
  z-index: 999;
  width: 35px;
  left: 0vw;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0vh;
  color: white;
  font-size: 1rem;
}

#fullscreen-btn:hover {
  cursor: pointer;
}

#skip-intro {
  user-select: none;
  position: absolute;
  padding: 1rem;
  border-bottom-left-radius: 0.8rem;
  border-top: none;
  font-weight: bold;
  background-color: #ffffff6c;
  z-index: 999;
  width: 35px;
  left: 100vw;
  transform: translateX(-99%);
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0vh;
  color: white;
  font-size: 1rem;
  display: none;
}

#skip-intro:hover {
  cursor: pointer;
}

* {
  -webkit-tap-highlight-color: transparent;
}

a:focus,
a:visited,
a:active {
  outline: none;
}

#more-infos {
  user-select: none;
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  font-size: 1.8rem;
  font-weight: 800;
  text-align: center;
}

#body-infos {
  margin: 0px 4rem;
  font-size: 1.4rem;
}

#back-infos {
  width: 5rem;

  transform: rotate(-90deg) translateX(1.5rem) scale(0.9);
}

#back-infos:hover {
  cursor: pointer;
}

@media screen and (max-width: 1400px) {
  #body-infos {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 1200px) {
  #body-infos {
    font-size: 0.85rem;
  }
  #back-infos {
    width: 40px;
  }
}

@media screen and (max-width: 998px) {
  #body-infos {
    font-size: 0.55rem;
  }
  #back-infos {
    width: 30px;
  }
}
